<h2 mat-dialog-title>AWK Zwischenspeichern</h2>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" color="warn">Schließen</button>
  <button mat-button color="primary" [mat-dialog-close]="true">
    {{ data.data }} Speichern
  </button>
</mat-dialog-actions>
