import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-save-awk-dialog',
  templateUrl: './save-awk-dialog.component.html',
  styleUrls: ['./save-awk-dialog.component.scss'],
})
export class SaveAwkDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
